export function feedbackMainTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Course',
      value: 'course'
    },
    {
      text: 'Package',
      value: 'package'
    },
    {
      text: 'Full name',
      value: 'fullName'
    },
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: 'Phone',
      value: 'phone'
    },
    {
      text: 'Created time',
      value: 'createdAt'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
