import mixins from '@/mixins'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {debounce} from 'lodash'
import {feedbackMainTableHead} from '../helpers/main'

export default {
  name: 'feedback-main',
  mixin: [mixins],
  data() {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  computed: {
    ...mapGetters({
      list: 'feedbacks/list',
      loading: 'feedbacks/listLoading',
      lengthList: 'feedbacks/listLength',
      limit: 'feedbacks/limit',
      skip: 'feedbacks/skip',
      removeListItemLoading: 'feedbacks/listLoading'
    }),
    feedbackMainTableHead
  },
  created() {
    this.fetchList()
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'feedbacks/GET_FEEDBACKS',
      remove: 'feedbacks/DELETE_FEEDBACK',
    }),
    ...mapMutations({
      changeSkip: 'feedbacks/FEEDBACK_LIST_CHANGE_SKIP',
      changeStepLock: 'feedbacks/FEEDBACK_CHANGE_LOCK_STEP',
      changeFilter: 'feedbacks/FEEDBACK_FILTER_CHANGE'
    }),
    deleteFeedback(id) {
      this.remove({id}).then(() => {
        this.$toasted.success(this.$t('success'));
        this.fetchList()
      })
    },
    changeFilterLocal(value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    changePagination(index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000),
    goToEditPage(id) {
      this.$router.push({name: 'feedbacks.edit', params: {feedback_id: id}}).catch(() => console.log())
    },
  }
}
